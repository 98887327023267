@tailwind base;
@tailwind components;
@tailwind utilities;

/*  */
/* ACCORDION */
/*  */
/* Style the buttons that are used to open and close the accordion panel */
.accordion {
    transition: 0.4s;
  }
  
  /* Style the accordion panel. Note: hidden by default */
  .panel {
    display: none;
    overflow: hidden;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }